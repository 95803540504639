import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`A Tooltip is a feedback component that displays a short, informational message when a user hovers over or focuses on a UI element.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/tooltip.svg",
      "alt": "tooltip anatomy banner"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Panel :`}</strong>{` Contains the Panel Content`}</li>
      <li parentName="ol"><strong parentName="li">{`Content :`}</strong>{` An area to display any text content`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`A tooltip can be added to any element if adding the tooltip increases the user’s comprehension. It is common to see tooltips added to icons, fields, and links. However it is important to use reason when adding tooltips to elements. Avoid adding tooltips to elements that don’t need description or elements that are so large it would be difficult to understand what the tooltip is describing.`}</p>
    <p><strong parentName="p">{`Common places buttons appear:`}</strong>{` Icons , Fields, links etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> To keep extra information off the screen but accessible.</li>
      <li> To add context to a given screen. </li>
      <li> To add explanations to visuals such as icons. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> For small bits of information connected to a single action </li>
      <li> For large and/or structured content </li>
      <li> For small interactions or choices</li>
      <li> To add hidden information to the screen itself</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Tooltips are great for keeping information initially hidden. In order for users to access it, they need to know it’s there. Showing the tooltip on hover or on keyboard focus of a focusable element ensures that all users can access it, even if they aren’t using a mouse.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="You can use show direct information with label instead" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/tooltip4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="use tooltips to communicate crucial information" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/tooltip3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating tooltip :`}</p>
    <ul>
      <li parentName="ul">{`Keep your tooltips clear and concise. Use the fewest number of words without sacrificing meaning.`}</li>
      <li parentName="ul">{`Tooltips should provide new and valuable information. Never use a tooltip to repeat information already available in the UI`}</li>
      <li parentName="ul">{`If the tooltip is a full sentence, include a period at the end. If the tooltip is a fragment, do not include a period at the end.`}</li>
      <li parentName="ul">{`Don’t use tooltips to communicate crucial information`}</li>
      <li parentName="ul">{`You can use tooltips to describe icons`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="use concise descriptions within tooltips" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/tooltip2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="wordy or lengthy descriptions within tooltips." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/tooltip5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      